import React, { useContext }  from 'react';
import { Container } from 'styled-bootstrap-grid';
import { ThemeContext } from 'styled-components';

import { NextRow, PaddingBottom, TableOfContents } from 'brandhub-core';

export default ({data, pageData}) => {
	const theme = useContext(ThemeContext);

	return (
		<PaddingBottom padding={{default: theme.pixels.px60}}>
			<Container>
				<NextRow>
					<TableOfContents data={data} pageData={pageData} />
				</NextRow>
			</Container>
		</PaddingBottom>
	)
}