import Button from './button';
import ComponentNotFound from './component-not-found.js';
import Carousel from './carousel';
import Colours from './colours';
import DoDont from './do-dont';
import DoubleMedia from './double-media';
import Downloads from './downloads';
import Footer from './footer';
import Header from './header';
import Headline from './headline';
import Hero from './hero';
import Image from './image';
import Information from './information';
import InspirationImages from './inspiration-images';
import Introduction from './introduction';
import Richtext from './richtext';
import MediaGroup from './media-group';
import MediaText from './media-text';
import Section from './section';
import TableOfContents from './table-of-contents';
import Teaser from './teaser';
import TextRow from './text-row';
import Video from './video';

const ComponentList = {
  button: Button,
  carousel: Carousel,
  colours: Colours,
  do_donts: DoDont,
	double_media: DoubleMedia,
  downloads: Downloads,
  Footer,
  header: Header,
  headline: Headline,
  hero: Hero,
  image: Image,
  information: Information,
  inspiration_images: InspirationImages,
	introduction: Introduction,
  richtext: Richtext,
	MediaGroup,
	media_text: MediaText,
	section: Section,
  table_of_contents: TableOfContents,
  teaser: Teaser,
  text_row: TextRow,
	video: Video
}

const Components = (type) => {
  if (typeof ComponentList[type] === 'undefined') {
    return ComponentNotFound
  }
  return ComponentList[type]
}

export default Components