import React, { useContext }  from 'react';
import { Container } from 'styled-bootstrap-grid';
import styled, { ThemeContext } from "styled-components";

import { NextRow, PaddingBottom, Richtext } from 'brandhub-core';

export default ({data}) => {
	const theme = useContext(ThemeContext);

	return (
		<PaddingBottom padding={{default: theme.pixels.px48}}>
			<Container>
				<NextRow>
					<Richtext data={data}/>
				</NextRow>
			</Container>
		</PaddingBottom>
	)
}
