import React, { useContext, useState } from "react"
import { Container, Row, Col } from "styled-bootstrap-grid"
import styled from "styled-components"
import { media } from "styled-bootstrap-grid"

import { AuthContext } from "../shared/auth"
import { useForm } from "../shared/validation"
import LoginTopbar from "../shared/components/login-topbar"
import {
  FormbuttonPrimary,
  FormbuttonMargin,
  FormBackLink,
  FormError,
  FormFieldError,
  FormGroup,
  FormHeading,
  FormInfo,
  FormInfoLink,
  FormInput,
  FormLabel,
  FormTopbar,
  FormWrapper
} from "brandhub-core"

const ForgotPassword = ({ location }) => {
  const auth = useContext(AuthContext)
  const [error, setError] = useState(false)

  // Validations
  const { handleSubmit, handleChange, data, errors } = useForm({
    validations: {
      username: {
        required: {
          value: true,
          message: "This field is required"
        }
      }
    },
    onSubmit: () => {
      auth.forgotPassword(data.username)
    },
  })

  return (
    <>
      <LoginTopbar />
      <FormWrapper as="form" onSubmit={handleSubmit}>
        <Container>
          <Row>
            <Col sm="12" mdOffset="2" md="8" xlOffset="3" xl="6">
              <FormBackLink text='Back to login' href="/login"/>
              <StyledFormHeading>Reset your password</StyledFormHeading>
              <StyledFormInfo>
                Please enter your user name. You will instantly receive further
                information by email.
              </StyledFormInfo>
              <FormGroup>
                <FormLabel htmlFor="username" error={errors.username}>Username</FormLabel>
                <FormInput
                  placeholder="Your username"
                  id="username"
                  type="text"
                  value={data.username}
                  error={errors.username}
                  onChange={handleChange('username')}
                />
                {errors.username && (<FormFieldError>{errors.username}</FormFieldError>)}
              </FormGroup>
              <ErrorGroup>
                <FormbuttonPrimary
                  as="input"
                  type="submit"
                  value="Send reset link"
                />
                {error && <StyledFormError>{error.message}</StyledFormError>}
              </ErrorGroup>
            </Col>
          </Row>
        </Container>
      </FormWrapper>
    </>
  )
}

export default ForgotPassword

const StyledFormInfoLink = styled(FormInfoLink)`
  margin-bottom: 120px;
  text-decoration: none;
`

const StyledFormHeading = styled(FormHeading)`
  margin-bottom: ${props => props.theme.pixels.px16}px;

  ${media.md`
    margin-bottom: ${props => props.theme.pixels.px16}px;
  `}

  ${media.lg`
    margin-bottom: ${props => props.theme.pixels.px20}px;
  `}
`

const StyledFormInfo = styled(FormInfo)`
  margin-bottom: 60px;
`

const StyledFormError = styled(FormError)`
  padding-left: 0;
  margin-top: 12px;

  ${media.lg`
    padding-left: 12px;
    margin-top: 0;
  `}
`

const ErrorGroup = styled.div`
  ${FormbuttonMargin}
  display: block;
  margin-bottom: 60px;

  ${media.lg`
    margin-bottom: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `}
`
