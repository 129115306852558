import { BaseTheme } from "brandhub-core"
import TextStyles from "./text-styles"

const colourPalette = {
  black: 'rgba(0, 0, 0, 0.72)',
  white: '#FFFFFF',
  primary: '#1562AD',

  // greys
  greyQiagen: '#65708B',
  greyNeutral: '#474746',
  greyLight: '#EEF0F4',
  greyMedium: '#D0D6DF',
  greyDark: '#0F1D41',

  // blues
  blueQiagen: '#004D9F',
  blueLight: '#CEE0F3',
  blueMedium: '#87A6D5',
  blueDark: '#1B3067',


  // colors
  red: '#E0003C',
  green: '#5CA083',
  blue: '#87A6D5',
  orange: '#FAB313'
}

const radius = {
  small: 20,
  large: 20
}

// Sidebar & Topbar dimensions
const structureSize = 300
const topbarHeight = 72

export const ProjectTheme = Object.assign({}, BaseTheme, {
  baseTheme: false,

  // TOKENS
  // Background & Surface
  background: colourPalette.white,
  surfaceLight: colourPalette.greyLight,
  surfaceLight2: colourPalette.blueLight,
  surfaceDark: colourPalette.blueDark,
  surfaceDark2: colourPalette.blueQiagen,

  // Text
  textBody: colourPalette.black,
  textBodyLight: colourPalette.white,
  textHeadline: colourPalette.primary,
  textHeadlineLight: colourPalette.white,
  activeNavLink: colourPalette.primary,
  textLink: colourPalette.black,
  textLinkHover: colourPalette.dark,

  // Signal
  sigSuccess: colourPalette.green,
  sigError: colourPalette.red,
  sigInfo: colourPalette.blue,

  // Other
  black: colourPalette.black,
  primaryColour: colourPalette.primary,
  itemBody: colourPalette.black,
  itemBodyLight: colourPalette.white,

  structureSize: structureSize,
  topbarHeight: topbarHeight,
  borderRadius: {
    small: radius.small,
    large: radius.large
  },

  ...TextStyles({
    textBody: colourPalette.black,
    textHeadline: colourPalette.primary,
  })
})
