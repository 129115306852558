import React, { useContext } from "react";
import { Container } from 'styled-bootstrap-grid';
import styled, { ThemeContext } from "styled-components";

import { NextRow, PaddingBottom, Colours, Card, CardInformation } from 'brandhub-core';

export default ({data}) => {
	const theme = useContext(ThemeContext);
	return (
		<PaddingBottom padding={{default: theme.pixels.px28}}>
			<Container>
				<NextRow>
					<StyledColours data={data} radius={'0px'} />
				</NextRow>
			</Container>
		</PaddingBottom>
	)
}

const StyledColours = styled(Colours)`
  ${Card} {
    border-radius: 0;
  }

  ${CardInformation} {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`