import React from 'react';

import Components from '../components.js';

export default ({data}) => {
	return (
		<header className="Introduction">
			{data.introduction?.map((intro, index) => (
				React.createElement(Components(intro.type), { key: `component-${index}`, component: intro.type, data: intro })
			))}
		</header>
	)
}