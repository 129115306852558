import React from "react"
import styled from "styled-components"

import { Topbar, NavigationLink } from "brandhub-core"
import { ReactComponent as QiagenLogo } from "./../../assets/icons/logo.svg"

const QiagenNavigationLink = styled(NavigationLink)`
  p {
    color: ${props => props.active ? props.theme.textHeadline : props.theme.textBody};
  }

  :after {
    background-color: ${props => props.theme.textHeadline};
  }
`

const QiagenStyledTopbar = styled(Topbar)`
  ${QiagenNavigationLink} {
  }
`

export const QiagenTopbar = ({
  items,
  isHome,
  isIncluded,
  sidebarVisible,
  setSidebarVisible,
  auth,
  search,
}) => {
  return (
    <>
      <QiagenStyledTopbar
        items={items}
        use={{
          logo: { default: QiagenLogo, mobile: QiagenLogo },
          navigationLink: QiagenNavigationLink,
          logoPadding: { top: 0, bottom: 0 }
        }}
        isHome={isHome}
        isIncluded={isIncluded}
        sidebarVisible={sidebarVisible}
        setSidebarVisible={setSidebarVisible}
        search={search}
        auth={auth}
      />
    </>
  )
}
