import React, { useContext } from "react"
import styled, { ThemeContext } from "styled-components"
import { Link } from "react-router-dom"

import { AuthContext } from "../../shared/auth"
import { ReactComponent as QiagenLogo } from "../../assets/icons/logo.svg"

import {
  BreakpointContext,
  FormTopbar,
  Icon,
  IconLogout,
} from "brandhub-core"

const LoginTopbar = () => {
  const theme = useContext(ThemeContext)
  const breakpoints = useContext(BreakpointContext);
  const auth = useContext(AuthContext)

  return (
    <FormTopbar>
      <Logo to={"/"} colour={theme.itemBody}>
        {breakpoints.mobile ? <QiagenLogo /> : <QiagenLogo />}
      </Logo>
      {auth.isLoggedIn && (
        <LogoutButton
          as="button"
          onClick={(event) => {
            auth.logout();
          }}
        >
          <Icon colour={theme.itemBody} icon={<IconLogout />} />
        </LogoutButton>
      )}
    </FormTopbar>
  )
}

export default LoginTopbar

const LogoutButton = styled.button`
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
`;

const Logo = styled(Link)`
  height: 100%;
  text-decoration: none;
  display: flex;
  align-items: center;

  svg {
    height: 100%;
    padding: 12px 0;
    max-width: 156px;
  }
`
