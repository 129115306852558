import React from "react"
import ReactDOM from "react-dom"

import BrandHubNext from "./shared/app"

// TODO: remove?
require("es6-promise").polyfill()

import "./shared/theme"

const render = element => {
  const config = {}
  ReactDOM.render(<BrandHubNext config={config} />, element)
}

render(document.querySelector(`.app`))
