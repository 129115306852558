import React, { useCallback, useEffect, useState } from "react"
import axios from "axios"

import { BACKEND_URL } from "brandhub-core"

export const AuthContext = React.createContext({})

const Authentication = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [token, setToken] = useState(false)

  const login = useCallback((email, password) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${BACKEND_URL}/auth/login`, {
          email: email,
          password: password
        })
        .then(response => {
          if (response.data.success === true) {
            setIsLoggedIn(true)
            persistToLocalstorage(response.data.token)
            resolve({ success: true })
          } else {
            setIsLoggedIn(false)
            reject({ message: "Invalid email address or password" })
          }
        })
    })
  }, [])

  const logout = () => {
    localStorage.removeItem("token")
    setIsLoggedIn(false)
    setToken(false)
    window.location.href = "/"
  }

  const forgotPassword = useCallback(username => {
    axios.post(`${BACKEND_URL}/auth/forgot-password`, {
      username: username
    })
  }, [])

  const register = useCallback(data => {
    return axios.post(`${BACKEND_URL}/auth/register`, data)
  }, [])

  const resolveFromUrl = () => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    if (params["with"]) {
      localStorage.setItem("token", params["with"])
    }
  }

  const resolveFromLocalstorage = () => {
    const token = localStorage.getItem("token")
    if (!isLoggedIn && token !== null) {
      setIsLoggedIn(true)
      /*axios.get(`${BACKEND_URL}/auth/verify?token=${token}`).then(response => {
        if (response.data.success === true) {
          setIsLoggedIn(true)
        }
      })*/
    }
  }

  const persistToLocalstorage = token => {
    localStorage.setItem("token", token)
  }

  resolveFromUrl()
  resolveFromLocalstorage()

  return (
    <AuthContext.Provider
      value={{
        forgotPassword: forgotPassword,
        isLoggedIn: isLoggedIn,
        login: login,
        logout: logout,
        register: register
      }}>
      {children}
    </AuthContext.Provider>
  )
}

export default Authentication
