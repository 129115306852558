import React, { Suspense, useContext } from "react"
import {
  BrowserRouter as Router,
  Redirect,
  useLocation,
  useRouteMatch
} from "react-router-dom"

import {
  BreakpointContextProvider,
  ScrollToTop,
  SEO,
  NavigationContextProvider
} from "brandhub-core"

import { ThemeProvider } from "styled-components"
import { GridThemeProvider } from "styled-bootstrap-grid"
import { GlobalStyle, Grid, ProjectTheme } from "../theme"
import Routes from "./routes"
import PageLoading from "../../components/page-loading"
import Authentication, { AuthContext } from "../auth"
import Favicon from "./../../assets/images/favicon.png"

const QiagenAuthGuard = ({ children, allowedLocations }) => {
  const auth = useContext(AuthContext)
  const location = useLocation()

  if (allowedLocations.indexOf(location.pathname) > -1 || auth.isLoggedIn) {
    return children
  } else {
    return (
      <Redirect
        to={{ pathname: "/login", state: { redirectTo: location.pathname } }}
      />
    )
  }
}

const BrandHubNext = () => {
  return (
    <Suspense fallback={<PageLoading />}>
      <SEO title='QIAGEN Brand Portal' defaultTitle='QIAGEN Brand Portal' image={Favicon} />
      <ThemeProvider theme={ProjectTheme}>
        {/* Provides theme to styled-components and JSX components */}
        <GridThemeProvider gridTheme={Grid}>
          {/* Provides grid-library to styled-components and JSX components */}
          <BreakpointContextProvider>
            <GlobalStyle /> {/* Injects global styles */}
            <Router basename={`/`}>
              <NavigationContextProvider>
                <Authentication>
                  <QiagenAuthGuard
                    allowedLocations={["/login", "/login/forgot", "/signup"]}>
                    <ScrollToTop />
                    <Routes />
                  </QiagenAuthGuard>
                </Authentication>
              </NavigationContextProvider>
            </Router>
          </BreakpointContextProvider>
        </GridThemeProvider>
      </ThemeProvider>
    </Suspense>
  )
}

export default BrandHubNext
