import { breakpoints, TextStyles } from 'brandhub-core'

export const fontFamilies = {
  heading: "futura-pt, Helvetica, Arial, sans-serif",
  body: "futura-pt, Helvetica, Arial, sans-serif",
	bold: "futura-pt, Helvetica, Arial, sans-serif"
};

export default (fontSettings) =>  {
	return Object.assign({}, TextStyles, {
		fontFamilies: {
			heading: fontFamilies.heading,
			body: fontFamilies.body
		},
		textStyles: {
			h1: {
				tag: "h1",
				fontSize: [32, 48, 60],
				fontWeight: 400,
				lineHeight: [1.2],
				fontFamily: fontFamilies.heading,
				fontStyle: 'normal',
				color: fontSettings.textHeadline
			},
			h2: {
				tag: "h2",
				fontSize: [24, 36, 48],
				fontWeight: 400,
				fontFamily: fontFamilies.heading,
				fontStyle: 'normal',
				lineHeight: [1.2],
				color: fontSettings.textHeadline
			},
			h3: {
				tag: "h3",
				fontWeight: 500,
				fontSize: [20, 28, 28],
				fontFamily: fontFamilies.heading,
				fontStyle: 'normal',
				lineHeight: [1.2],
				color: fontSettings.textHeadline
			},
			h4: {
				tag: "h4",
				fontWeight: 500,
				fontSize: [18, 22, 22],
				fontFamily: fontFamilies.heading,
				fontStyle: 'normal',
				lineHeight: [1.2],
				color: fontSettings.textHeadline
			},
			intro: {
				tag: "p",
				fontWeight: 400,
				fontSize: [16, 18, 20],
				fontFamily: fontFamilies.body,
				fontStyle: 'normal',
				lineHeight: [1.4],
				color: fontSettings.textBody
			},
			copy: {
				tag: "p",
				fontSize: [14, 15, 15],
				fontWeight: 500,
				lineHeight: [1.4],
				fontFamily: fontFamilies.body,
				fontStyle: 'normal',
				color: fontSettings.textBody
			},
			bold: {
				tag: "p",
				fontSize: [14, 15, 15],
				fontWeight: 700,
				lineHeight: [1.4],
				fontFamily: fontFamilies.bold,
				fontStyle: 'normal',
				color: fontSettings.textBody
			},
			italic: {
				tag: "em",
				fontSize: [14, 15, 15],
				fontWeight: 400,
				lineHeight: [1.4],
				fontFamily: fontFamilies.body,
				fontStyle: 'italic',
				color: fontSettings.textBody
			},
			label: {
				tag: "p",
				fontSize: [14, 15, 15],
				fontWeight: 400,
				lineHeight: [1.2],
				fontFamily: fontFamilies.body,
				fontStyle: 'normal',
				color: fontSettings.textBody
			}
		},
		breakpoints: [`${breakpoints.sm}px`, `${breakpoints.md}px`]
	})
}