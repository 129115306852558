import React, { useContext, useEffect, useState, lazy } from "react"
import { Switch, Route, useLocation } from "react-router-dom"
import { AnimatePresence } from "framer-motion"

import { NavigationContext } from "brandhub-core"

import Page from "../../pages/page"

const Login = lazy(() => import("../../pages/login"))
const ForgotPassword = lazy(() => import("../../pages/forgot-password"))
const Signup = lazy(() => import("../../pages/signup"))
const Home = lazy(() => import("../../pages"))
const PageNotFound = lazy(() => import("../../pages/404"))

const Routes = () => {
  const location = useLocation()
  return (
    <AnimatePresence exitBeforeEnter>
      <Switch key={location.pathname}>
        {/*<Switch location={location} key={location.pathname}> */}
        <Route exact path="/" render={() => <Home />} />
        <Route
          path="/login/forgot"
          render={props => <ForgotPassword {...props} />}
        />
        <Route path="/login" render={props => <Login {...props} />} />
        <Route path="/signup" render={props => <Signup {...props} />} />
        <Route path="/:slug+" render={() => <Page />} />
        <Route component={PageNotFound} />
      </Switch>
    </AnimatePresence>
  )
}

export default Routes
