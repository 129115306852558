import React, { useContext } from "react";
import { Container } from 'styled-bootstrap-grid';
import { ThemeContext } from 'styled-components';

import { NextRow, PaddingBottom, MediaGroup } from 'brandhub-core';

export default ({ data }) => {
  const theme = useContext(ThemeContext);

  return (
		<PaddingBottom padding={{default: theme.pixels.px60}}>
			<Container>
				<NextRow>
					<MediaGroup data={data}></MediaGroup>
				</NextRow>
			</Container>
		</PaddingBottom>
  );
};
