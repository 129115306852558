import React, { useContext } from 'react';
import styled, { ThemeContext } from "styled-components";

import { Hero } from 'brandhub-core';

export default ({data}) => {
	const theme = useContext(ThemeContext);

	return (
    <HeroWrapper>
      <Hero data={data}></Hero>
    </HeroWrapper>
	)
}

const HeroWrapper = styled.div`
	border-top-left-radius: ${props => props.theme.pixels.px16}px;
	border-top-right-radius: ${props => props.theme.pixels.px16}px;
  overflow: hidden;
`