import React, { useContext } from "react"
import styled, { ThemeContext } from "styled-components"
import { Container } from "styled-bootstrap-grid"
import { Heading } from "brandhub-core"

const Component = styled(Container)`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100vh;
`

const PageNotFound = () => {
  const theme = useContext(ThemeContext)
  return (
    <Component>
      <Heading heading={"h1"} styles={theme.textStyles}></Heading>
    </Component>
  )
}

export default PageNotFound
