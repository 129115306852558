import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { Container } from 'styled-bootstrap-grid';

import { NextRow } from 'brandhub-core';

const Loader = styled.div`

`

export default ({data}) => {
  const theme = useContext(ThemeContext);

	return (
		<Loader>
			<Container>
				<NextRow>
					
				</NextRow>
			</Container>
		</Loader>
	)
}