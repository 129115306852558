import React from 'react'
import styled from "styled-components";

const Component = styled.div`
  box-shadow: 0 0 4px 2px rgba(255, 103, 103, 0.3);
  border: 1px solid #FF6767;
  background-color: #FFA2A2;
  height: 20vh;
  margin-bottom: 64px;
  padding: 32px;
  width: 100%;
`

const ComponentNotFound = ({component, data}) => {
  return (
    <Component>
      <p>Component "{component}" is not defined.</p>
			<p>Create component in '/components' and add it to components.js or check the reference from the cms</p>
    </Component>
  )
}

export default ComponentNotFound