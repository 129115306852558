import React, { useContext }  from 'react';
import { Container } from 'styled-bootstrap-grid';
import { ThemeContext } from 'styled-components';

import { NextRow, PaddingBottom, Headline } from 'brandhub-core';

export default ({data}) => {
	const theme = useContext(ThemeContext);

	return (
		<PaddingBottom padding={{default: theme.headingMargin[data.headlineType]}}>
			<Container>
				<NextRow>
					<Headline data={data}></Headline>
				</NextRow>
			</Container>
		</PaddingBottom>
	)
}