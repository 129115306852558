import React, { useContext, useState, useEffect } from "react"
import { useHistory } from "react-router"
import { Container, Row, Col, media } from "styled-bootstrap-grid"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { motion, useAnimation } from "framer-motion"

import { AuthContext } from "../shared/auth"
import { useForm } from "../shared/validation"
import LoginTopbar from "../shared/components/login-topbar"
import BackgroundImage from "../assets/images/login-background.png"

import {
  FormbuttonPrimary,
  FormbuttonSecondary,
  FormbuttonMargin,
  FormCopy,
  FormCopyLink,
  FormError,
  FormFieldError,
  FormGroup,
  FormHeading,
  FormHeading2,
  FormInfo,
  FormInfoLink,
  FormInput,
  FormLabel,
  FormWrapper
} from "brandhub-core"

const Login = ({ location }) => {
  const auth = useContext(AuthContext)

  const history = useHistory()
  const [error, setError] = useState(false)
  const [visible, setVisible] = useState(false)
  const [employeeNotFound, setEmployeeNotFound] = useState(false)

  const loginAnimation = useAnimation()
  const loginVariants = {
    visible: { y: "0%", display: "block" },
    hidden: { y: "100%", display: "none" }
  }

  useEffect(() => {
    loginAnimation.start(visible ? "visible" : "hidden")
  }, [visible])

  // Validations
  const required = {
    value: true,
    message: "This field is required"
  }

  const { handleSubmit, handleChange, data, errors } = useForm({
    validations: {
      email: {
        required: required,
        pattern: {
          value: /\S+@\S+\.\S+/,
          message: "Please provide a valid email address"
        }
      },
      password: {
        required: required
      }
    },
    onSubmit: () => {
      auth
        .login(data.email, data.password)
        .then(response => {
          setError(false)
          history.push("/")
        })
        .catch(response => {
          setError(response)
        })
    }
  })

  const handleEmployeeLogin = () => {
    window.location.href = `/saml2/azure/login`
  }

  return (
    <Wrapper>
      <LoginTopbar />
      <LoginIntro backgroundImage={BackgroundImage}>
        <Container>
          <Row>
            <Col sm="12" mdOffset="2" md="8" xlOffset="3" xl="6">
              <StyledFormHeading white={true}>
                Welcome to the QIAGEN Brand Portal
              </StyledFormHeading>
              <FormInfoIntro white={true}>
                The place where you find all information about the QIAGEN Brand
              </FormInfoIntro>
              <FormbuttonPrimary
                white={true}
                onClick={() => {
                  setVisible(true)
                }}>
                Start here
              </FormbuttonPrimary>
            </Col>
          </Row>
        </Container>
      </LoginIntro>
      <MotionDiv
        variants={loginVariants}
        initial={"hidden"}
        animate={loginAnimation}
        transition={{ duration: 0.4, ease: "easeInOut" }}>
        <StyledFormWrapper>
          <Container>
            <Row>
              <Col sm="12" mdOffset="2" md="8" xlOffset="3" xl="6">
                <FormHeading>Log In</FormHeading>
                <>
                  <FormCopyLogin>
                    If you're an employee, enjoy logging in with just one click.
                  </FormCopyLogin>
                  <ErrorGroup>
                    <FormbuttonPrimary
                      as="button"
                      onClick={handleEmployeeLogin}>
                      Log in as employee
                    </FormbuttonPrimary>
                    {employeeNotFound && (
                      <StyledFormError>
                        This email adress is not registered
                      </StyledFormError>
                    )}
                  </ErrorGroup>
                </>
                <form onSubmit={handleSubmit}>
                  <FormHeading2>Log in as external</FormHeading2>
                  <FormGroup>
                    <FormLabel htmlFor="email" error={errors.email}>
                      Email
                    </FormLabel>
                    <FormInput
                      placeholder="yourname@qiagen.com"
                      id="email"
                      type="text"
                      value={data.email}
                      error={errors.email}
                      onChange={handleChange("email")}
                    />
                    {errors.email && (
                      <FormFieldError>{errors.email}</FormFieldError>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <FormLabel htmlFor="password" error={errors.password}>
                      Password
                    </FormLabel>
                    <FormInput
                      placeholder="••••••"
                      id="password"
                      type="password"
                      value={data.password}
                      error={errors.password}
                      onChange={handleChange("password")}
                    />
                    {errors.password && (
                      <FormFieldError>{errors.password}</FormFieldError>
                    )}
                  </FormGroup>
                  <ErrorGroup>
                    <FormbuttonSecondary
                      as="input"
                      type="submit"
                      value="Log in"
                    />
                    {error && (
                      <StyledFormError>{error.message}</StyledFormError>
                    )}
                  </ErrorGroup>
                </form>
                <FormCopyLogin>
                    You need an account to access the Brand Portal. If you don’t
                    have one,{" "}
                    <FormCopyLink as="a" href="/signup">
                      request an account
                    </FormCopyLink>.
                  </FormCopyLogin>
                  {/*<FormInfo>
                    Forgot your password?{" "}
                    <StyledFormInfoLink as="a" href="/login/forgot">
                      Reset it here
                    </StyledFormInfoLink>.
                  </FormInfo>*/}
              </Col>
            </Row>
          </Container>
        </StyledFormWrapper>
      </MotionDiv>
    </Wrapper>
  )
}

export default Login

export const Logo = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;

  svg {
    height: 100%;
    padding: 12px 0;
    max-width: 156px;
  }
`

const Wrapper = styled.div`
  position: relative;
`

const LoginIntro = styled(FormWrapper)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.primaryColour};
  background-image: url(${props => props.backgroundImage});
  background-repeat: no-repeat;
  background-position: right top;
  padding: 0;
`
const MotionDiv = styled(motion.div)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  padding: 80px 0;
  height: 80%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: ${props => props.theme.background};
`

const StyledFormWrapper = styled(FormWrapper)`
  padding-top: 0;
`

const StyledFormHeading = styled(FormHeading)`
  margin-bottom: 48px;
`

const FormInfoIntro = styled(FormInfo)`
  margin-bottom: 80px;
`

const FormCopyLogin = styled(FormCopy)`
  margin-bottom: 32px;
`

const StyledFormInfoLink = styled(FormInfoLink)`
  display: inline-block;
  text-decoration: underline;
`

const StyledFormError = styled(FormError)`
  padding-left: 0;
  margin-top: 12px;

  ${media.lg`
    padding-left: 12px;
    margin-top: 0;
  `}
`

const ErrorGroup = styled.div`
  ${FormbuttonMargin}
  display: block;
  margin-bottom: 60px;

  ${media.lg`
    margin-bottom: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `}
`

const LogoutButton = styled.button`
  display: none;

  @media only screen and (min-width: 1200px) {
    display: block;
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
  }
`
