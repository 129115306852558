import React, { useContext } from "react"
import { Container, Col, media } from "styled-bootstrap-grid"
import styled, { ThemeContext } from "styled-components"

import { NextRow, Paragraph, FormCopyLink } from "brandhub-core"

const LinkFooter = ({ isHome, hasSidebar, sidebarVisible }) => {
  const theme = useContext(ThemeContext)

  return (
    <Wrapper sidebarVisible={sidebarVisible} fullPage={!isHome() && hasSidebar}>
      <Container>
        <Row>
          <StyledCol col sm="12">
            <List>
              <li>
                <Paragraph styles={theme.textStyles}>
                  <Link href="https://www.qiagen.com/" target="_blank">
                    qiagen.com
                  </Link>
                </Paragraph>
              </li>
              <li>
                <Paragraph styles={theme.textStyles}>
                  <Link
                    href="https://www.qiagen.com/trademarks-and-disclaimers/"
                    target="_blank">
                    Disclaimers
                  </Link>
                </Paragraph>
              </li>
              <li>
                <Paragraph styles={theme.textStyles}>
                  <Link
                    href="https://www.qiagen.com/trademarks-and-disclaimers/privacy-policy"
                    target="_blank">
                    Privacy
                  </Link>
                </Paragraph>
              </li>
              <li>
                <Paragraph styles={theme.textStyles}>
                  <Link
                    href="https://www.qiagen.com/trademarks-and-disclaimers/impressum/ "
                    target="_blank">
                    Impressum
                  </Link>
                </Paragraph>
              </li>
            </List>
            <Paragraph styles={theme.textStyles}>
              © QIAGEN 2015–{new Date().getFullYear().toString().substr(-2)},
              All rights reserved
            </Paragraph>
          </StyledCol>
        </Row>
      </Container>
    </Wrapper>
  )
}

export default LinkFooter

const Wrapper = styled.footer`
  width: 100%;
  background-color: ${props => props.theme.background};
  border-top-left-radius: ${props => props.theme.borderRadius.large}px;
  border-top-right-radius: ${props => props.theme.borderRadius.large}px;
  margin-top: ${props => -props.theme.borderRadius.large}px;
  margin-left: 0;
  transition: all 0.4s ease-in-out;

  @media only screen and (min-width: 1220px) {
    width: ${props =>
      props.fullPage && props.sidebarVisible
        ? `calc(100% - ${props.theme.structureSize}px)`
        : "100%"};
    margin-left: ${props =>
      props.fullPage && props.sidebarVisible ? props.theme.structureSize : 0}px;
  }
`

const Row = styled(NextRow)`
  padding: 32px 0;
`

const StyledCol = styled(Col)`
  display: block;

  ${media.lg`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}
`

const List = styled.ul`
  list-style: none;
  padding: none;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 8px;

  ${media.lg`
    margin-bottom: 0;
  `}
`

const Link = styled.a`
  display: inline-block;
  padding: 0;
  padding-right: 20px;
  transition: all 0.1s ease-in-out;
  text-decoration: none;
  cursor: pointer;

  font-family: ${props => props.theme.fontFamilies.body};
  font-weight: ${props => props.theme.textStyles.copy.fontWeight};
  line-height: ${props => props.theme.textStyles.copy.lineHeight[0]};
  font-size: ${props => props.theme.textStyles.copy.fontSize[0]}px;
  color: ${props => props.theme.textBody};

  ${media.md`
    font-size: ${props => props.theme.textStyles.copy.fontSize[1]}px;
  `}

  ${media.lg`
    font-size: ${props => props.theme.textStyles.copy.fontSize[2]}px;
  `}

  :hover {
    opacity: 0.8;
  }
`
