import React, { useContext } from "react"
import styled, { ThemeContext } from "styled-components"
import { Container } from "styled-bootstrap-grid"
import { useLocation } from "react-router-dom"

import { NextRow, PaddingBottom, Image } from "brandhub-core"

export default ({ data }) => {
  const theme = useContext(ThemeContext)
  const location = useLocation()

  return (
    <PaddingBottom
      padding={{
        default: theme.pixels.px60
      }}>
      <Container>
        <NextRow>
          <Image
            data={data}
            radius={'0px'}
          />
        </NextRow>
      </Container>
    </PaddingBottom>
  )
}
