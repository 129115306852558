import React from "react"
import styled from "styled-components"

import { NextSidebar, SidebarItem, Parent } from "brandhub-core"
import { ReactComponent as QiagenLogo } from "./../../assets/icons/logo.svg"

const QiagenParent = styled(Parent)`
  font-weight: 400;
`

const QiagenSidebarItem = styled(SidebarItem)`
  p {
    font-weight: 400;
  }

  a {
    &:hover p {
      color: ${props => props.theme.textHeadline};
    }
  }
`

const QiagenStyledSidebar = styled(NextSidebar)`
  position: fixed;
  top: 0;
  width: ${props => props.theme.structureSize}px;

  ${QiagenParent} {
  }
  ${QiagenSidebarItem} {
  }
`

export const QiagenSidebar = ({
  currentNode,
  currentRoot,
  items,
  isHome,
  sitemap,
  sidebarVisible,
  setSidebarVisible,
  isIncluded,
  isMobile,
  isCurrentPage,
  className,
  search,
  hasSidebar,
  auth
}) => {
  return (
    <QiagenStyledSidebar
      currentNode={currentNode}
      currentRoot={currentRoot}
      items={items}
      use={{
        logo: { default: QiagenLogo, mobile: QiagenLogo },
        logoPadding: { top: 0, bottom: 0 },
        sidebarItem: QiagenSidebarItem,
        parent: QiagenParent
      }}
      isHome={isHome}
      isIncluded={isIncluded}
      isMobile={isMobile}
      isCurrentPage={isCurrentPage}
      sidebarVisible={sidebarVisible}
      setSidebarVisible={setSidebarVisible}
      auth={auth}
      className={className}
      search={search}
      sitemap={sitemap}
      hasSidebar={hasSidebar}
    />
  )
}
