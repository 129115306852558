import React, { useEffect, useContext, useState } from "react"
import { useLocation } from "react-router-dom"
import { NavigationContext, Search } from "brandhub-core"
import { AuthContext } from "../shared/auth"

import { QiagenTopbar } from "../components/topbar"
import { QiagenSidebar } from "../components/sidebar"
import { DefaultContent } from "../components/content"
import Footer from "../components/footer"
import LinkFooter from "../shared/components/link-footer"

const Layout = ({ children }) => {
  const location = useLocation()
  const navigation = useContext(NavigationContext)
  const auth = useContext(AuthContext)
  const hasSidebar = navigation.hasSidebar()
  const [showSearch, setShowSearch] = useState(false)

  useEffect(() => {
    navigation.resizeHandler()
  }, [location])

  return (
    <>
      <QiagenSidebar
        currentNode={navigation.currentNode}
        currentRoot={navigation.currentRoot}
        items={navigation.sidebarMenu}
        isHome={navigation.isHome}
        sidebarVisible={navigation?.sidebarVisible}
        setSidebarVisible={navigation?.setSidebarVisible}
        isIncluded={navigation.isIncluded}
        isMobile={navigation.isMobile}
        search={{ showSearch: showSearch, setShowSearch: setShowSearch }}
        isCurrentPage={navigation.isCurrentPage}
        sitemap={navigation.sitemap}
        hasSidebar={hasSidebar}
        auth={auth}
      />
      <QiagenTopbar
        items={navigation.topbarMenu}
        isHome={navigation.isHome}
        isIncluded={navigation.isIncluded}
        sidebarVisible={navigation.sidebarVisible}
        setSidebarVisible={navigation.setSidebarVisible}
        search={{ showSearch: showSearch, setShowSearch: setShowSearch }}
        auth={auth}
      />
      <DefaultContent
        parentPage={navigation.parentPage}
        sidebarVisible={navigation.sidebarVisible}
        hasSidebar={hasSidebar}
        isHome={navigation.isHome}
        parentPageHasChildren={navigation.parentPageHasChildren}>
        {children}
      </DefaultContent>
      <Search
        allPages={navigation.allPages}
        sitemap={navigation.sitemap}
        search={{ showSearch: showSearch, setShowSearch: setShowSearch }}
      />
      { !navigation.isHome() && <Footer
        currentNode={navigation.currentNode}
        isHome={navigation.isHome}
        hasSidebar={hasSidebar}
        sidebarVisible={navigation.sidebarVisible}
      />}
      <LinkFooter
        isHome={navigation.isHome}
        hasSidebar={hasSidebar}
        sidebarVisible={navigation.sidebarVisible}
      />
    </>
  )
}

export default Layout
