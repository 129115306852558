import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { Container } from "styled-bootstrap-grid";

import { NextRow, Footer, breakpoints, BreakpointContext } from "brandhub-core";

const FooterWrapper = styled.div`
  width: 100%;
  background-color: ${(props) => props.colour ? props.colour : props.theme.black};
  border-top-left-radius: ${(props) => `${props.radius}px`};
  border-top-right-radius: ${(props) => `${props.radius}px`};
  margin-top: ${props => -props.theme.borderRadius.large}px;
  z-index: 4000;
  margin-left: 0;
  transition: all 0.4s ease-in-out;

  @media only screen and (min-width: 1220px) {
    width: ${props =>
      props.fullPage && props.sidebarVisible
        ? `calc(100% - ${props.theme.structureSize}px)`
        : "100%"};
    margin-left: ${props =>
      props.fullPage && props.sidebarVisible ? props.theme.structureSize : 0}px;
  }
`

export default ({
  currentNode,
  isHome,
  hasSidebar,
  sidebarVisible,
}) => {
  const theme = useContext(ThemeContext);
  const { currentWindowWidth } = useContext(BreakpointContext);

  return (
    <FooterWrapper radius={theme.borderRadius.large} colour={theme.surfaceLight} sidebarVisible={sidebarVisible} fullPage={!isHome() && hasSidebar}>
      <Container>
        <NextRow>
          <Footer
            currentNode={currentNode}
            isHome={isHome}
            hasSidebar={hasSidebar}
            sidebarVisible={sidebarVisible}
            wording={{
              next: 'Next',
              previous: 'Previous',
              chapter: ' Chapter',
              guideline: ' Guideline',
              library: ' Library',
              resources: '/resources'
            }}
            padding={{
              top:
                currentWindowWidth >= breakpoints.md
                  ? theme.pixels.px64
                  : theme.pixels.px24,
              bottom:
                currentWindowWidth >= breakpoints.md
                  ? theme.pixels.px80
                  : theme.pixels.px24,
            }}
            colour={theme.primaryColour}
          />
        </NextRow>
      </Container>
    </FooterWrapper>
  );
};
