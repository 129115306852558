import React, { useContext } from "react";
import { Col, Container } from "styled-bootstrap-grid";
import styled, { ThemeContext } from "styled-components";

import { ImageAtom, AspectRatio, LazyLoad, BreakpointContext, breakpoints, NextRow } from 'brandhub-core';


export default ({ data }) => {
	const theme = useContext(ThemeContext);
  const { currentWindowWidth } = useContext(BreakpointContext);

  const imageAspectRatio = (image) => {
    if (currentWindowWidth < breakpoints.sm) {
      return image.ratio || 1.77;
    } else if (
      currentWindowWidth < breakpoints.lg &&
      currentWindowWidth >= breakpoints.sm
    ) {
      return image.ratioMd
        ? image.ratioMd
        : image.ratio
        ? image.ratio
        : 1.77;
    } else {
      return image.ratioLg
        ? image.ratioLg
        : image.ratio
        ? image.ratio
        : 1.77;
    }
  };

  return (
    <>
      {data.layout.map((layout, index) => (
        <StyledContainer key={index}>
          <NextRow>
            { layout && layout.images.map((item, index) =>
              <StyledCol
                col
                xs={12}
                sm={12}
                md={ layout.type === 'double_images' ? 6 : 12 }
                key={index}
              >
                <AspectRatio ratio={imageAspectRatio(item)} key={index}>
                  <LazyLoad
                    height={"100%"}
                    ratio={imageAspectRatio(item.image)}
                    once={true}
                    offset={100}
                  >
                    <ImageAtom data={item.image} radius={0} />
                  </LazyLoad>
                </AspectRatio>
              </StyledCol>
            )}
          </NextRow>
        </StyledContainer>
      ))}
    </>
  );
};

const StyledContainer = styled(Container)`
  padding-right: 43px;
  padding-left: 43px;
`

const StyledCol = styled(Col)`
	padding-right: 0;
	padding-left: 0;

  img {
    padding-bottom: 10px;
    padding-right: 5px;
    padding-left: 5px;
    height: 100%;
  }
`