import React, { useEffect } from "react"
import styled from "styled-components"

const Main = styled.main`
  height: 100%;
  width: 100%;
  min-height: 100vh;
  background-color: ${props => props.theme.background};
  margin-top: ${props => props.theme.topbarHeight}px;
  transition: all 0.4s ease-in-out;
  margin-left: 0;
  @media only screen and (min-width: 1220px) {
    width: ${props =>
      props.fullPage && props.sidebarVisible
        ? `calc(100% - ${props.theme.structureSize}px)`
        : "100%"};
    margin-left: ${props =>
      props.fullPage && props.sidebarVisible ? props.theme.structureSize : 0}px;
  }
`

export const DefaultContent = ({
  children,
  isHome,
  hasSidebar,
  sidebarVisible
}) => {
  return (
    <Main sidebarVisible={sidebarVisible} fullPage={!isHome() && hasSidebar}>
      {children}
    </Main>
  )
}
