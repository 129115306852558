import React, { useContext } from "react";
import { Container } from 'styled-bootstrap-grid';
import { ThemeContext } from 'styled-components';

import { NextRow, PaddingBottom, Downloads } from 'brandhub-core';

export default ({data}) => {
  const theme = useContext(ThemeContext);

	return (
		<PaddingBottom padding={{default: theme.pixels.px48}}>
			<Container>
				<NextRow>
					<Downloads data={data}></Downloads>
				</NextRow>
			</Container>
		</PaddingBottom>
	)
}