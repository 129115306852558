import React, { useLayoutEffect, useContext, useEffect, useState } from "react"

import Layout from "./../layouts/default.js"
import PageNotFound from "./404.js"
import Components from "../components/components.js"

import { NavigationContext, sanitize } from "brandhub-core"

const Page = () => {
  const navigation = useContext(NavigationContext)

  return navigation.currentNode ? (
    <Layout>
      {!navigation.isLoading && (
        <div>
          {navigation.currentNode.page.page_builder.map((item, index) =>
            React.createElement(Components(item.type), {
              key: `component-${index}`,
              component: item.type,
              data: sanitize(item),
              pageData: navigation.currentNode.page.page_builder
            })
          )}
        </div>
      )}
    </Layout>
  ) : (
    <PageNotFound />
  )
}

export default Page
