import React, { useContext } from "react";
import { Container } from 'styled-bootstrap-grid';
import { ThemeContext } from 'styled-components';

import { NextRow, PaddingBottom, Carousel } from 'brandhub-core';

export default ({data}) => {
	const theme = useContext(ThemeContext);
	return (
		<PaddingBottom padding={{default: theme.pixels.px60}}>
			<Container>
				<NextRow>
					<Carousel data={data} radius={'0px'}/>
				</NextRow>
			</Container>
		</PaddingBottom>
	)
}