import React, { useContext, useState } from "react"
import { Container, Row, Col } from "styled-bootstrap-grid"
import styled, { ThemeContext } from "styled-components"
import { media } from "styled-bootstrap-grid"
import { useHistory } from "react-router-dom"

import { AuthContext } from "../shared/auth"
import { useForm } from "../shared/validation"
import LoginTopbar from "../shared/components/login-topbar"
import {
  FormbuttonPrimary,
  FormbuttonMargin,
  FormBackLink,
  FormCopy,
  FormDropdownInput,
  FormError,
  FormFieldError,
  FormGroup,
  FormHeading,
  FormHeading2,
  FormInput,
  FormLabel,
  FormTextArea,
  FormWrapper
} from "brandhub-core"

const Signup = ({ location }) => {
  const auth = useContext(AuthContext)
  const [error, setError] = useState(false)
  const history = useHistory()

  const required = {
    value: true,
    message: "This field is required"
  }

  const { handleSubmit, handleChange, data, errors } = useForm({
    validations: {
      qiagenEmail: {
        required: required,
        pattern: {
          value: /\b[\w\.-]+@qiagen.com/,
          message: "Please provide a QIAGEN email address"
        }
      },
      repeatQiagenEmail: {
        required: required,
        custom: {
          isValid: value => data?.qiagenEmail === value,
          message: "The email adresses don't match"
        }
      },
      email: {
        required: required,
        pattern: {
          value: /\S+@\S+\.\S+/,
          message: "Please provide a valid email address"
        }
      },
      repeatEmail: {
        required: required,
        custom: {
          isValid: value => data?.email === value,
          message: "The email adresses don't match"
        }
      },
      firstName: {
        required: required
      },
      lastName: {
        required: required
      },
      reason: {
        required: required
      }
    },
    onSubmit: () => {
      auth
        .register({
          firstname: data["firstName"],
          lastname: data["lastName"],
          email: data["repeatEmail"],
          contact_email: data["repeatQiagenEmail"],
          reason: data["reason"] ? data["reason"] : ""
        })
        .then(response => {
          const { success, errors } = response.data
          if (success === false) {
            // alert('An error occurred while finishing your registration')
            alert("The email you have entered already exists")
            console.log(errors)
          } else {
            history.push("/")
          }
        })
    }
  })

  return (
    <>
      <LoginTopbar />
      <FormWrapper as="form" onSubmit={handleSubmit} novalidate>
        <Container>
          <Row>
            <Col sm="12" mdOffset="2" md="8" xlOffset="3" xl="6">
              <FormBackLink text="Back to login" href="/login" />
              <FormHeading>Request access data</FormHeading>
              <StyledFormCopy>
                This website is intended for QIAGEN employees and business
                partners. If you need access and do not yet have a user account,
                you can request one here.
                <br />
                <br />
                If you do not have a QIAGEN email account, you must have access
                approved by QIAGEN - please fill in the additional fields.
              </StyledFormCopy>
              <Spacer>
                <FormHeading2>Your contact person at QIAGEN</FormHeading2>
                <ContactFormCopy>
                  Add the name of your contact person and a reason for your
                  account request, to ensure that the entire information for
                  granting your request is available.
                </ContactFormCopy>
                <SidebySide>
                  <StyledFormGroup>
                    <FormLabel
                      htmlFor="qiagen-email"
                      error={errors.qiagenEmail}>
                      E-Mail
                    </FormLabel>
                    <FormInput
                      placeholder="yourcontact@qiagen.com"
                      id="qiagen-email"
                      type="email"
                      value={data.qiagenEmail}
                      error={errors.qiagenEmail}
                      onChange={handleChange("qiagenEmail")}
                    />
                    {errors.qiagenEmail && (
                      <FormFieldError>{errors.qiagenEmail}</FormFieldError>
                    )}
                  </StyledFormGroup>
                  <StyledFormGroup>
                    <FormLabel
                      htmlFor="repeat-qiagen-email"
                      error={errors.repeatQiagenEmail}>
                      Repeat E-Mail
                    </FormLabel>
                    <FormInput
                      placeholder="yourcontact@qiagen.com"
                      id="repeat-qiagen-email"
                      type="email"
                      value={data.repeatQiagenEmail}
                      error={errors.repeatQiagenEmail}
                      onChange={handleChange("repeatQiagenEmail")}
                    />
                    {errors.repeatQiagenEmail && (
                      <FormFieldError>
                        {errors.repeatQiagenEmail}
                      </FormFieldError>
                    )}
                  </StyledFormGroup>
                </SidebySide>
                <FormGroup>
                  <FormLabel htmlFor="reason" error={errors.reason}>
                    Reason for request
                  </FormLabel>
                  <FormTextArea
                    id="reason"
                    type="text"
                    as="textarea"
                    value={data.reason}
                    onChange={handleChange("reason")}
                  />
                  {errors.reason && (
                    <FormFieldError>{errors.reason}</FormFieldError>
                  )}
                </FormGroup>
              </Spacer>
              <FormHeading2>Your details</FormHeading2>
              <SidebySide>
                <StyledFormGroup>
                  <FormLabel htmlFor="first-name" error={errors.firstName}>
                    First name
                  </FormLabel>
                  <FormInput
                    placeholder="First"
                    id="first-name"
                    type="text"
                    value={data.firstName}
                    error={errors.firstName}
                    onChange={handleChange("firstName")}
                  />
                  {errors.firstName && (
                    <FormFieldError>{errors.firstName}</FormFieldError>
                  )}
                </StyledFormGroup>
                <StyledFormGroup>
                  <FormLabel htmlFor="last-name" error={errors.lastName}>
                    Last name
                  </FormLabel>
                  <FormInput
                    placeholder="Last"
                    id="last-name"
                    type="text"
                    value={data.lastName}
                    error={errors.lastName}
                    onChange={handleChange("lastName")}
                  />
                  {errors.lastName && (
                    <FormFieldError>{errors.lastName}</FormFieldError>
                  )}
                </StyledFormGroup>
              </SidebySide>
              <SidebySide>
                <StyledFormGroup>
                  <FormLabel htmlFor="email" error={errors.email}>
                    E-Mail
                  </FormLabel>
                  <FormInput
                    placeholder="your@email.com"
                    id="email"
                    type="email"
                    value={data.email}
                    error={errors.email}
                    onChange={handleChange("email")}
                  />
                  {errors.email && (
                    <FormFieldError>{errors.email}</FormFieldError>
                  )}
                </StyledFormGroup>
                <StyledFormGroup>
                  <FormLabel htmlFor="repeat-email" error={errors.repeatEmail}>
                    Repeat E-Mail
                  </FormLabel>
                  <FormInput
                    placeholder="your@email.com"
                    id="repeat-email"
                    type="email"
                    value={data.repeatEmail}
                    error={errors.repeatEmail}
                    onChange={handleChange("repeatEmail")}
                  />
                  {errors.repeatEmail && (
                    <FormFieldError>{errors.repeatEmail}</FormFieldError>
                  )}
                </StyledFormGroup>
              </SidebySide>
              <ErrorGroup>
                <FormbuttonPrimary
                  as="input"
                  type="submit"
                  value="Request access"
                />
                {error && <StyledFormError>{error.message}</StyledFormError>}
              </ErrorGroup>
            </Col>
          </Row>
        </Container>
      </FormWrapper>
    </>
  )
}

export default Signup

const StyledFormCopy = styled(FormCopy)`
  margin-bottom: 80px;
`

const ContactFormCopy = styled(FormCopy)`
  margin-bottom: 32px;
`

const SidebySide = styled.div`
  width: 100%;
  display: block;
  margin-bottom: 16px;

  ${media.lg`
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
  `}
`

const StyledFormGroup = styled(FormGroup)`
  width: 100%;

  :last-child {
    margin-bottom: 0;
  }

  ${media.lg`
    width: 49%;
  `}
`

const Spacer = styled.div`
  margin-bottom: 48px;
`

const StyledFormError = styled(FormError)`
  padding-left: 0;
  margin-top: 12px;

  ${media.lg`
    padding-left: 12px;
    margin-top: 0;
  `}
`

const ErrorGroup = styled.div`
  ${FormbuttonMargin}
  display: block;
  margin-bottom: 60px;

  ${media.lg`
    margin-bottom: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `}
`
