import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";

import { Header } from "brandhub-core";

const HeaderWrapper = styled.div`
	border-top-left-radius: ${props => props.theme.borderRadius.large}px;
	border-top-right-radius: ${props => props.theme.borderRadius.large}px;
  overflow: hidden;
`

export default ({data}) => {
  const theme = useContext(ThemeContext);

	return (
		<HeaderWrapper>
			<Header data={data} radius={'0px'}/>
		</HeaderWrapper>
	)
}