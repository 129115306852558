import React, { useContext } from "react"
import styled, { ThemeContext } from "styled-components"
import { media } from "styled-bootstrap-grid"

import Components from "../components.js"

export default ({ data, pageData }) => {
  const theme = useContext(ThemeContext)
  const fullSize = data.full_size
  const whiteText = data.background.includes("surfaceDark")
  const sectionBackground = data.background

  return (
    <Section
      colour={data.background}
      image={data.image}
      $borderRadius={data.radius}
      className="Section">
      {data.modules &&
        data.modules.map((module, index) =>
          React.createElement(Components(module.type), {
            key: `component-${index}`,
            component: module.type,
            data: { ...module, whiteText, sectionBackground },
            pageData: { pageData }
          })
        )}
    </Section>
  )
}

const Section = styled.section`
  height: ${props => (props.image ? "40vh" : "auto")};
  background-color: ${props => props.image ? props.theme.background : props.theme[props.colour]};
  padding-top: ${props => props.image ? 0 : props.padding ? props.padding.top : props.theme.pixels.px60}px;
  padding-bottom: ${props => props.image ? 0 : props.padding ? props.padding.bottom : props.theme.pixels.px100}px;
  overflow: hidden;
  border-top-left-radius: ${props => props.$borderRadius ? props.theme.borderRadius.large : 0}px;
  border-top-right-radius: ${props => props.$borderRadius ? props.theme.borderRadius.large : 0}px;
  margin-top: ${props => props.$borderRadius ? -props.theme.borderRadius.large : 0}px;
  background-image: ${props => props.image ? `url(${props.image.srcLg || props.image.src})` : "none"};
  background-size: cover;
  background-position: center center;

  &:first-child {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  & > div:last-child {
    padding-bottom: 0;
  }

  ${media.md`
    background-image: ${props => props.image ? `url(${props.image.srcMd || props.image.src})` : "none"};
  `}
  ${media.lg`
    background-image: ${props => props.image ? `url(${props.image.src})` : "none"};
    padding-top: ${props =>
      props.image
        ? 0
        : props.padding
        ? props.padding.top
        : props.theme.pixels.px80}px;
    padding-bottom: ${props =>
      props.image
        ? 0
        : props.padding
        ? props.padding.bottom
        : props.theme.pixels.px120}px;
  `}
`
